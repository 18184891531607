import { useAuth0 } from '@auth0/auth0-react'
import {
  AlertColor,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from '@mui/material'
import FormControl from '@mui/material/FormControl'
import axios, { AxiosResponse } from 'axios'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import * as React from 'react'
import { Dispatch, useState } from 'react'
import { IUserPhone } from '../../interfaces'
import { envParams, getOptions } from '../../utils'

const updateUser = async (
  controlRiskServerUrl: string,
  token: string,
  user: IUserPhone
): Promise<AxiosResponse | undefined> => {
  const url = `${controlRiskServerUrl}/user`
  const options = getOptions(token)
  try {
    return await axios.patch(
      url,
      user,
      options
    )
  } catch (error) {
    console.log(new Error('Error updating Phone'))
    console.error(error)
  }
}

export function CrDialogPhone ({
  openDialogPhone,
  setOpenDialogPhone,
  handleOpenSnackbar
}: {
  openDialogPhone: boolean
  setOpenDialogPhone: Dispatch<React.SetStateAction<boolean>>
  handleOpenSnackbar: (type: AlertColor, message: string) => void
}) {
  const [phone, setPhone] = useState<string | undefined>(undefined)
  const [error, setError] = useState<boolean>(false)
  const [helperText, setHelperText] = useState('');
  const [loading, setLoading] = useState(false)
  const { user, getAccessTokenSilently } = useAuth0()

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPhone(value);

    const phoneNumber = parsePhoneNumberFromString(value, 'ES')
    if (!phoneNumber || !phoneNumber.isValid()) {
      setError(true);
      setHelperText('El numero de teléfono parece incorrecto');
    } else {
      setError(false);
      setHelperText('');
    }
  }
  const handleCloseDialogPhone = (): void => {
    setOpenDialogPhone(false)
    setPhone('')
    setError(false)
    setHelperText('')
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault()

    if (!error && phone) {
      setLoading(true)
      
      const crUser: IUserPhone = {
        id: user?.cr_id || '',
        phone: phone || ''
      }
      const controlRiskServerUrl = envParams.HOSTNAME_SERVER
      const token = await getAccessTokenSilently();
      const response: AxiosResponse | undefined = await updateUser(
        controlRiskServerUrl,
        token,
        crUser,
      )
      
      if (response?.status === 204) {
        handleOpenSnackbar('success', 'Gracias, ahora puedes descargar los ficheros!')
        handleCloseDialogPhone()
        setLoading(false)
      } else {
        handleOpenSnackbar('error', 'Imposible actualizar tú perfil en este momento. Vuelve a intentarlo!')
        setLoading(false)
      }
    } else {
      handleOpenSnackbar('error', 'Imposible actualizar tú perfil. Parece que el número del teléfono es incorrecto.')
      setLoading(false)
    }
  }

  return (
    <>
      <Dialog
        open = { openDialogPhone }
        onClose = { handleCloseDialogPhone }
        PaperProps={{
          component: 'form',
          onSubmit: handleSubmit,
        }}
        fullWidth
        maxWidth={'sm'}
      >
        <DialogTitle>
          Añade tú número de teléfono
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Antes de poder descargar y utilizar el servicio de Control Risk te pedimos tú número de teléfono.<br />
            El motivo es que a cambio de un Control Risk gratuito, queremos poder contactarte, dentro de unos dias, para que un experto pueda recoger un feedback de tu parte.
          </DialogContentText>
          <FormControl fullWidth sx={{ my: 2 }}>
            <TextField
              id="phone"
              label="Teléfono"
              variant="outlined"
              type="tel"
              fullWidth
              onChange={handlePhoneChange}
              error={error}
              helperText={helperText}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={handleCloseDialogPhone} color="inherit">Anular</Button>
          <Button variant="contained" type="submit" onClick={handleSubmit} color="primary" disabled={loading}>
            {loading ? <CircularProgress size={24} color="inherit" /> : 'Añadir'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}