import { useAuth0 } from '@auth0/auth0-react'
import {
  Box,
  Button,
  Grid,
  Typography
} from '@mui/material'

export function Landing () {
  const { loginWithRedirect } = useAuth0()

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <Grid
        container
        spacing={4}
        sx={{
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Grid
          item
          xs={12}
          md={5}
        >
          <Box
            component="img"
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 5
            }}
            alt="Sample Image"
            src="landing.png"
          />
        </Grid>
        <Grid
          item xs={12}
          md={7}
        >
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Typography variant="h2">
              Bienvenido en
            </Typography>
            <Typography variant="h1">
              Control Risk
            </Typography>
          </Box>
          <Box>
            <Button
              variant="contained"
              size="large"
              color="primary"
              sx={{ marginY: 3 }}
              onClick={() => loginWithRedirect()}
            >
              Comenzar
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
