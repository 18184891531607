import {
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { IAccount } from '../../interfaces'
import { numberFormat } from '../../utils'
import { CrTableDashboardRow } from './CrTableDashboardRow'

export function CrTableDashboard ({
  accounts,
  loadingDashboard,
}: {
  accounts: IAccount[] | undefined
  loadingDashboard: boolean
}) {
  const { t } = useTranslation('dashboard')
  let openedOrderinitialPositionsTotal = 0
  let openedOrderactualPositionsTotal = 0
  let equityTotal = 0
  let pygTotal = 0
  let balanceTotal = 0
  let actualLeverageTotal = 0
  let futureLeverageTotal = 0

  if (accounts && accounts.length) {
    accounts.forEach((account: IAccount) => {
      actualLeverageTotal += account.openedOrderLeverage || 0
      futureLeverageTotal += (account.openedOrderLeverage || 0) + (account.pendingOrderLeverage || 0)
      openedOrderinitialPositionsTotal += account.orders?.opened?.reduce((acc, cur) => acc + cur.initialPositionsValue, 0) || 0
      openedOrderactualPositionsTotal += account.orders?.opened?.reduce((acc, cur) => acc + cur.actualPositionsValue, 0) || 0
      equityTotal += account.equity
      pygTotal += account.profit
      balanceTotal += account.balance
    })
  }
  const floatingTotal = (pygTotal / balanceTotal) * 100

  return (
    <>
      <Typography variant="h5" sx={{ mt: 3 }} gutterBottom>
        {t('accounts')}
      </Typography>
      <TableContainer component={Paper} sx={{ mb: 2 }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{ bgcolor: 'grey.800' }}>
            <TableRow>
              <TableCell/>
              <TableCell>{t('account')}</TableCell>
              <TableCell align="right">
                <span dangerouslySetInnerHTML={{ __html: t('actual-leverage') }} />
              </TableCell>
              <TableCell align="right">
                <span dangerouslySetInnerHTML={{ __html: t('future-leverage') }} />
              </TableCell>
              <TableCell align="right">
                <span dangerouslySetInnerHTML={{ __html: t('initial-position') }} />
              </TableCell>
              <TableCell align="right">
                <span dangerouslySetInnerHTML={{ __html: t('actual-position') }} />
              </TableCell>
              <TableCell align="right">{t('equity')}</TableCell>
              <TableCell align="right">{t('profit-and-loss')}</TableCell>
              <TableCell align="right">{t('floating')}</TableCell>
              <TableCell align="right">{t('balance')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {loadingDashboard ? (
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              </TableRow>
            ) : (
              !accounts ? (
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell colSpan={11} align="center" sx={{ color: 'error.main' }}>
                    {t('unknown-error')}
                  </TableCell>
                </TableRow>
              ) : !accounts.length ? (
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell colSpan={11} align="center">
                    {t('no-account-available')}
                  </TableCell>
                </TableRow>
              ) : (
                <>
                  {accounts.map((account: IAccount) => (
                    <CrTableDashboardRow
                      account = { account }
                    />
                  ))}
                  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell />
                    <TableCell align="right">{t('total')}</TableCell>
                    <TableCell align="right">{numberFormat(actualLeverageTotal)}</TableCell>
                    <TableCell align="right">{numberFormat(futureLeverageTotal)}</TableCell>
                    <TableCell align="right">{numberFormat(openedOrderinitialPositionsTotal)}&nbsp;€</TableCell>
                    <TableCell align="right">{numberFormat(openedOrderactualPositionsTotal)}&nbsp;€</TableCell>
                    <TableCell align="right">{numberFormat(equityTotal)}&nbsp;€</TableCell>
                    <TableCell align="right" sx={(pygTotal < 0) ? { color: 'error.main' } : { color: 'success.main' }}>{numberFormat(pygTotal)}&nbsp;€</TableCell>
                    <TableCell align="right" sx={(floatingTotal < 0) ? { color: 'error.main' } : { color: 'success.main' }}>{numberFormat(floatingTotal)}&nbsp;%</TableCell>
                    <TableCell align="right">{numberFormat(balanceTotal)}&nbsp;€</TableCell>
                    <TableCell />
                  </TableRow>
                </>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
