import {
  Alert,
  AlertColor,
  Snackbar
} from '@mui/material'

export function CrSnackbar ({
  openSnackbar,
  handleCloseSnackbar,
  typeSnackbar,
  messageSnackbar
}: {
  openSnackbar: boolean
  handleCloseSnackbar: (event: React.SyntheticEvent | Event, reason: string) => void
  typeSnackbar: AlertColor
  messageSnackbar: string
}) {
  return (
    <Snackbar
      open={openSnackbar}
      autoHideDuration={2500}
      onClose={handleCloseSnackbar}
    >
      <Alert
        severity={typeSnackbar}
        variant="filled"
        sx={{ width: '100%' }}
      >
        {messageSnackbar}
      </Alert>
    </Snackbar>
  )
}
