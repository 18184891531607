import { useAuth0 } from '@auth0/auth0-react'
import { AccountCircle, /* Language, */ Logout } from '@mui/icons-material'
import KeyIcon from '@mui/icons-material/Key'
import {
  AppBar,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
  Typography
} from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import DownloadIcon from '@mui/icons-material/Download'

export function CrAppBar ({
  handleCopyApiKey,
  handleValidateUserPhone
}: {
  handleCopyApiKey: (apiKey: string) => void
  handleValidateUserPhone: (assetId: string) => void
}) {
  const { /* i18n, */ t } = useTranslation('appBar')
  const { logout, user } = useAuth0()
  const [anchorElUser, setAnchorElUser] = useState(null)
  const [anchorElApiKey, setAnchorElApiKey] = useState(null)
  const [anchorElDownload, setAnchorElDownload] = useState(null)
  // const [anchorElLang, setAnchorElLang] = useState(null)
  
  const handleMenuUser = (event: any) => {
    setAnchorElUser(event.currentTarget)
  }
  const handleCloseUser = () => {
    setAnchorElUser(null)
  }

  const handleMenuApiKey = (event: any) => {
    setAnchorElApiKey(event.currentTarget)
  }
  const handleCloseApiKey = () => {
    setAnchorElApiKey(null)
  }

  const handleMenuDownload = (event: any) => {
    setAnchorElDownload(event.currentTarget)
  }
  const handleCloseDownload = () => {
    setAnchorElDownload(null)
  }

  // const handleMenuLang = (event: any) => {
  //   setAnchorElLang(event.currentTarget)
  // }
  // const handleCloseLang = () => {
  //   setAnchorElLang(null)
  // }
  // const changeLanguage = (lng: any) => {
  //   i18n.changeLanguage(lng)
  //   handleCloseLang()
  // }

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          {t('title')}
        </Typography>
        {/* <div>
          <IconButton
            size="large"
            aria-label="choose language"
            aria-controls="menu-appbar-lang"
            aria-haspopup="true"
            onClick={handleMenuLang}
            color="inherit"
          >
            <Language />
          </IconButton>
          <Menu
            id="menu-appbar-lang"
            anchorEl={anchorElLang}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElLang)}
            onClose={handleCloseLang}
          >
            <MenuItem onClick={() => changeLanguage('es')}>{t('es')}</MenuItem>
            <MenuItem onClick={() => changeLanguage('en')}>{t('en')}</MenuItem>
          </Menu>
        </div> */}
        <div>
          <IconButton
            size="large"
            aria-label="user download"
            aria-controls="menu-appbar-download"
            aria-haspopup="true"
            onClick={handleMenuDownload}
            color="inherit"
          >
            <DownloadIcon />
          </IconButton>
          <Menu
            id="menu-appbar-download"
            anchorEl={anchorElDownload}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElDownload)}
            onClose={handleCloseDownload}
          >
            <MenuItem disabled>Elige tu versión de Meta Trader:</MenuItem>
              <Divider />
            <MenuItem onClick={() => handleValidateUserPhone('mt4')}>Meta Trader 4</MenuItem>
            <MenuItem onClick={() => handleValidateUserPhone('mt5')}>Meta Trader 5</MenuItem>
          </Menu>
        </div>
        <div>
          <IconButton
            size="large"
            aria-label="user api key"
            aria-controls="menu-appbar-apikey"
            aria-haspopup="true"
            onClick={handleMenuApiKey}
            color="inherit"
          >
            <KeyIcon />
          </IconButton>
          <Menu
            id="menu-appbar-apikey"
            anchorEl={anchorElApiKey}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElApiKey)}
            onClose={handleCloseApiKey}
          >
            <MenuItem disabled>Haz click para copiar tu Api Key:</MenuItem>
              <Divider />
            <MenuItem onClick={() => handleCopyApiKey(user?.cr_api_key)}>{user?.cr_api_key}</MenuItem>
          </Menu>
        </div>
        <div>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar-user"
            aria-haspopup="true"
            onClick={handleMenuUser}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar-user"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUser}
          >
            <MenuItem disabled>{user?.email}</MenuItem>
              <Divider />
            <MenuItem onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
              <ListItemIcon>
                  <Logout fontSize="small" />
              </ListItemIcon>
              {t('sign-out')}
            </MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  )
}
