import { Auth0Provider } from '@auth0/auth0-react'
import ReactDOM from 'react-dom/client'
import { I18nextProvider } from 'react-i18next'
import { App } from './App'
import i18n from './lang/i18n'
import { envParams } from './utils'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <Auth0Provider
    domain = { envParams.AUTH0_DOMAIN }
    clientId = { envParams.AUTH0_CLIENT_ID }
    authorizationParams = {{
      redirect_uri: window.location.origin,
      audience: envParams.AUTH0_AUDIENCE,
    }}
  >
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </Auth0Provider>
)